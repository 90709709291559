<template>
  <div>
    <el-card class="box-card" v-loading="loading">
      <el-row :gutter="20" v-if="showButtons">
        <el-col :span="24">
          <el-button type="primary" @click="openHeidi">Open Scribe</el-button>
          <el-button type="primary" @click="openHeidiWithTemplate">
            Open Scribe with Template
          </el-button>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :md="24" :lg="12">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>Patient Info</span>
            </div>
            <el-form :label-position="'top'" label-width="100px" :model="patientInfo">
              <el-form-item label="Patient ID">
                <el-input v-model="patientInfo.id"></el-input>
              </el-form-item>
              <el-form-item label="Patient Name">
                <el-input v-model="patientInfo.name"></el-input>
              </el-form-item>
              <el-form-item label="Patient Gender">
                <el-select v-model="patientInfo.gender" placeholder="Select">
                  <el-option
                    v-for="item in genderOpt"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Patient DOB">
                <el-date-picker v-model="patientInfo.dob" type="date" placeholder="Pick a day">
                </el-date-picker>
              </el-form-item>
            </el-form>
          </el-card>
        </el-col>
        <el-col :md="24" :lg="12">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>Custom Template</span>
            </div>
            <JSONEditor ref="jsonEditor" v-model="responses" />
          </el-card>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { v4 as uuid } from "uuid";
import JSONEditor from "@/components/JSONEditor";

export default {
  components: {
    JSONEditor,
  },
  data() {
    return {
      loading: true,
      apiKey: "",
      email: "",
      enviroment: "staging",
      internalId: "",
      showButtons: false,
      heidiInstance: null,
      genderOpt: [
        {
          value: "Male",
          label: "Male",
        },
        {
          value: "Female",
          label: "Female",
        },
      ],
      patientInfo: {
        id: "",
        name: "",
        gender: "",
        dob: "",
      },
      templateData: {
        content: "...",
        responses: [],
      },
      responses: [
        {
          questionId: "31",
          question: "Medical History",
          answerType: "SingleResponse",
          answerOptions: ["Checked - No Changes", "Updated (Please provide details)"],
        },
        {
          questionId: "32",
          question: "Who did the Patient Attended With",
          answerType: "SingleResponse",
          answerOptions: ["Parent", "Guardian", "Self"],
        },
        {
          questionId: "33",
          question: "Mucosa, Tongue, Fom, Palate ",
          answerType: "TextArea",
          answerOptions: [],
        },
        {
          questionId: "34",
          question: "Caries Risk",
          answerType: "SingleResponse",
          answerOptions: ["Low Risk", "Medium Risk", "High Risk"],
        },
        {
          questionId: "35",
          question: "Development of Dentition",
          answerType: "SingleResponse",
          answerOptions: ["Early", "Normal", "Delayed"],
        },
        {
          questionId: "36",
          question: "Charting Completed ?",
          answerType: "SingleResponse",
          answerOptions: ["Yes", "No"],
        },
        {
          questionId: "37",
          question: "Patient Cooperation",
          answerType: "SingleResponse",
          answerOptions: ["Good", "Poor"],
        },
        {
          questionId: "38",
          question: "Patient Diagnosis",
          answerType: "TextArea",
          answerOptions: [],
        },
        {
          questionId: "39",
          question: "Discussions | Child Exam",
          answerType: "MultipleResponse",
          answerOptions: [
            "OHI - CheckBox All Checked (brush then supervise brushing, twice daily, spit do not rinse out, fluoride tp, children electric TB)",
            "Diet advice  Check Box All Checked (hidden sugars, limit to meal times and no more than 4 times per day)",
          ],
        },
        {
          questionId: "40",
          question: "Treatment Plan Agreement",
          answerType: "TextArea",
          answerOptions: [],
        },
      ],
      heidiOptions: {
        token: "VALID_JWT_TOKEN",
        target: "#heidi",
        onInit: this.onHeidiInit,
        onReady: this.onHeidiReady,
      },
    };
  },
  methods: {
    async getToken() {
      this.internalId = uuid();
      const responses = await fetch(
        `https://registrar.api.heidihealth.com/api/v2/ml-scribe/open-api/jwt?email=${this.email}&third_party_internal_id=${this.internalId}`,
        {
          method: "GET",
          headers: {
            "Heidi-Api-Key": this.apiKey,
          },
        }
      );

      const data = await responses.json();
      this.heidiOptions.token = data.token;
      this.loading = false;
      this.loadHeidi();
    },
    loadHeidi() {
      // Load the Heidi script when the component is mounted
      const script = document.createElement("script");
      script.async = true;
      script.src = "https://registrar.widget.heidihealth.com/staging/v1.2.1/heidi.js";

      const scripts = document.getElementsByTagName("script");

      for (let index = 0; index < scripts.length; index++) {
        const element = scripts[index];
        if (element.src.includes("heidi.js")) {
          element.remove();
        }
      }

      document.head.appendChild(script);
      script.addEventListener("load", () => {
        new Heidi(this.heidiOptions);
      });
    },
    openHeidiWithTemplate() {
      this.templateData.responses = this.responses;

      if (this.patientInfo.id) {
        Heidi.open({
          template: this.templateData,
          patient: this.patientInfo,
        });
      } else {
        Heidi.open({
          template: this.templateData,
        });
      }
    },
    openHeidi() {
      if (this.patientInfo.id) {
        Heidi.open({ patient: this.patientInfo });
      } else {
        Heidi.open();
      }
    },
    closeHeidi() {
      Heidi.close();
    },
    onHeidiInit() {
      this.showButtons = true; // Show the buttons when Heidi is initialized
    },
    onHeidiReady() {
      Heidi.onPushData((data) => {
        console.log(data); // Handle data received from Heidi
      });

      Heidi.onSessionStarted((sessionId) => {
        console.log("Session ID:", sessionId); // Handle session started
      });
    },
  },
  mounted() {
    this.apiKey = this.$store.state.modules.scribe.apiKey;
    this.email = this.$store.state.modules.scribe.email;
    this.enviroment = this.$store.state.modules.scribe.environment;
    if (this.apiKey && this.email) {
      this.getToken();
    } else {
      this.loading = false;
    }
  },
};
</script>
